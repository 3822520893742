import React from "react";
import Layout from "./../components/Layout/Layout";
import Logo from "../images/logoWhite.png";
import "../styles/HomeStyles.css";

const Home = () => {
  return (
    <Layout>
      <div className="home">
        <div className="headerContainer">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        <div>
          <p className="headerContainerP">Yenileniyoruz...</p>
        </div>
      </div>
    </Layout>
  );
};

export default Home;
