import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    tr: {
        translation: {
            "aboutUs": "Hakkımızda",
            "aboutUsDescription1": "1987 yılında kuyumculuk setörünün merkezinde İstanbul Kapalı Çarşı’da bir kuyumcu atölyesinde işe başlayan ve yıllar içinde edinilmiş bilgi, birikim ve tecrübeleri bir araya toplayıp günümüzde Serkan Gürel tarafından kurulan Turab Mücevherat, sektörde öncü firmalardan biri olarak Elmas Kule’de faaliyet göstermektedir. Turab Mücevherat, yenilikçi vizyonu ve kaliteli ürün hizmetiyle, müşterilerine en iyi hizmeti sunmayı amaçlamaktadır.",
            "aboutUsDescription2": "Sektördeki uzun yıllara dayanan bilgi birikimi ve deneyimi sayesinde, ithalat, ihracat ve üretim alanında da kaliteyi temsil etmektedir. Ürün yelpazemiz, özgün olarak tasarlanmış ve üretilmiş çeşitli takılardan oluşmaktadır.",
            "aboutUsDescription3": "Profesyonel ekibimizle, lokal pazar, Orta Doğu, Avrupa ve Amerika pazarlarına yönelik kaliteli hizmet sunmaktayız. Turab Mücevherat, her zaman müşteri memnuniyeti ve yüksek kaliteyi ön planda tutan yenilikçi ruhuyla, kuyumculuk dünyasında hızla ilerlemeye devam etmektedir.",
            "home": "Ana Sayfa",
            "menu": "Menu",
            "contact": "İletişim"
        }
    },
    en: {
        translation: {
            "aboutUs": "About Us",
            "home": "Home",
            "menu": "Menu",
            "aboutUsDescription1": "Turab Mücevherat, which started its business in 1987 in a jeweler's workshop in the Istanbul Grand Bazaar, at the center of the jewelery industry, and was founded by Serkan Gürel by gathering the knowledge, experience and experience gained over the years, operates in Elmas Kule as one of the leading companies in the sector. Turab Mücevherat aims to provide the best service to its customers with its innovative vision and quality product service.",
            "aboutUsDescription2": "Thanks to its many years of knowledge and experience in the sector, it also represents quality in the fields of import, export and production. Our product range consists of a variety of uniquely designed and manufactured jewellery.",
            "aboutUsDescription3": "With our professional team, we provide quality service for the local market, the Middle East, European and American markets. Turab Mücevherat continues to progress rapidly in the world of jewelry with its innovative spirit that always prioritizes customer satisfaction and high quality.",
            "contact": "Contact"
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        lng: 'tr',
        resources
    });

export default i18n;
