// WhatsAppIcon.js
import React from 'react';
import "../../styles/HomeStyles.css";
import WpImage from "../../images/wp3.png"

const WhatsAppIcon = () => {
  return (
    <a href="https://wa.me/905512705353" target="_blank" rel="noopener noreferrer" className="whatsapp-icon">
      <img src={WpImage}  alt="WhatsApp" />
    </a>
  );
};

export default WhatsAppIcon;
